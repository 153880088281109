<template>
  <div>
    <SpinnerLogo />
  </div>
</template>

<script>
import bluebird from 'bluebird';
import { mapActions, mapGetters } from 'vuex';

import SpinnerLogo from '@/components/SpinnerLogo';
const initialModals = {
  assetToolsUpdateImages: () => ({ show: false, data: [], form: [], updating: false })
};

export default {
  name: 'AdminToolsAsset',
  components: {
    SpinnerLogo
  },
  data() {
    return {
      loading: false,
      modals: {
        assetToolsUpdateImages: initialModals.assetToolsUpdateImages()
      }
    };
  },
  computed: {
    ...mapGetters({
      loadingAction: 'asset/loadingAction',
      assets: 'asset/assets'
    }),
    assetToolsAssets() {
      const assets = [...this.assets];

      assets.sort((a, b) => a.siteName.localeCompare(b.siteName));

      return assets.map(a => ({ value: a.id, label: `${a.siteName} (${a.entity?.legalName})` }));
    }
  },
  async mounted() {
    await this.listAssets({ data: { params: { $populate: 'entity', $select: 'entity.legalName' } } });
    this.loading = false;
  },
  methods: {
    ...mapActions({
      listAssets: 'asset/list',
      updateSatelliteImages: 'asset/updateSatelliteImages'
    }),
    onClickUpdateImages() {
      // TODO: MOVE TO OWN COMPONENT
      this.modals.assetToolsUpdateImages.updating = true;

      this.modals.assetToolsUpdateImages.data = [...this.modals.assetToolsUpdateImages.form].map(id => ({
        id: id,
        siteName: this.assets.find(a => a.id === id).siteName,
        status: 'pending',
        error: ''
      }));

      bluebird.map(
        this.modals.assetToolsUpdateImages.data,
        async (asset, idx) => {
          this.$set(this.modals.assetToolsUpdateImages.data[idx], 'status', 'loading');

          const response = await this.updateSatelliteImages({ id: asset.id });

          if (response._id && response.images) {
            this.$set(this.modals.assetToolsUpdateImages.data[idx], 'status', 'success');
          } else {
            this.$set(this.modals.assetToolsUpdateImages.data[idx], 'status', 'failed');
            console.log(response);
            this.$set(this.modals.assetToolsUpdateImages.data[idx], 'error', response.body?.error?.message);
          }
        },
        { concurrency: 1 }
      );
    },
    resetModals() {
      this.modals.assetToolsUpdateImages = initialModals.assetToolsUpdateImages();
    }
  }
};
</script>
